import React, { useState, useEffect } from 'react';
import { Layout, Typography, Row, Col, Card, Space, Button } from 'antd';
import { motion } from 'framer-motion';
import { Context } from '../store/Context';
import { useContext } from 'react';
import { BookOutlined, ToolOutlined, MessageOutlined, MailOutlined, QuestionCircleOutlined, GlobalOutlined, PhoneOutlined, ArrowRightOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { getLocale, getBrowserLanguage } from '../locales';

// 游戏模拟器主题
const theme = {
  // 主色调 - 紫色系
  primary: '#6c5ce7',
  primaryLight: '#a29bfe',
  primaryDark: '#5341d8',
  
  // 次要色 - 青色系
  secondary: '#00cec9',
  secondaryLight: '#81ecec',
  secondaryDark: '#00b5b0',
  
  // 强调色 - 加入一点橙色元素
  accent: '#fd79a8',
  accentLight: '#fab1a0',
  
  // 背景色和文本色
  bgDark: '#1e1e2e',
  bgMedium: '#2d2d3f',
  bgLight: '#393a58',
  textPrimary: '#ffffff',
  textSecondary: '#d0d0d7',
  textMuted: '#a0a0b0',
  
  // 渐变色
  gradientPrimary: 'linear-gradient(120deg, #6c5ce7 0%, #5341d8 100%)',
  gradientSecondary: 'linear-gradient(120deg, #00cec9 0%, #00b5b0 100%)',
  gradientAccent: 'linear-gradient(120deg, #fd79a8 0%, #fab1a0 100%)',
  gradientBackground: 'linear-gradient(150deg, #1e1e2e 0%, #2d2d3f 100%)'
};

function Support() {
    const { state } = useContext(Context);
    const [locale, setLocale] = useState(getBrowserLanguage());
    const localeText = getLocale(locale);

    useEffect(() => {
        // Add some smooth page entrance animation
        document.body.style.overflowX = 'hidden';
        document.body.style.background = theme.bgDark;
        
        return () => {
            document.body.style.background = '';
            document.body.style.overflowX = '';
        };
    }, []);

    const toggleLanguage = () => {
        setLocale(locale === 'en' ? 'zh' : 'en');
    };

    // 只保留前三个卡片
    const supportCards = [
        {
            icon: <BookOutlined style={{ fontSize: 36, color: theme.primary }} />,
            title: locale === 'zh' ? '使用指南' : 'User Guide',
            description: locale === 'zh' ? '学习如何使用模拟器' : 'Learn how to use the emulator',
            link: '/tutorial',
            color: theme.primary
        },
        {
            icon: <MailOutlined style={{ fontSize: 36, color: theme.secondary }} />,
            title: locale === 'zh' ? '邮件支持' : 'Email Support',
            description: locale === 'zh' ? '通过邮件联系我们' : 'Contact us via email',
            subText: 'unds@hiappstore.com',
            link: 'mailto:unds@hiappstore.com',
            color: theme.secondary
        },
        {
            icon: <QuestionCircleOutlined style={{ fontSize: 36, color: theme.accent }} />,
            title: locale === 'zh' ? '常见问题' : 'FAQs',
            description: locale === 'zh' ? '查找常见问题的解答' : 'Find answers to common questions',
            link: '/faq',
            color: theme.accent
        }
    ];

    return (
        <Layout style={{ 
            minHeight: '100vh', 
            background: theme.bgDark,
            color: theme.textPrimary
        }}>
            <Layout.Content style={{ 
                padding: '60px 24px',
                maxWidth: '1200px',
                margin: '0 auto'
            }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: 40 }}>
                    <Typography.Title level={1} style={{ 
                        margin: 0,
                        color: theme.textPrimary,
                        fontSize: 'clamp(1.8rem, 1.5rem + 1.5vw, 2.5rem)',
                        fontWeight: 700
                    }}>
                        {locale === 'zh' ? '需要帮助？' : 'Need Help?'}
                    </Typography.Title>
                    <Button 
                        type="text" 
                        onClick={toggleLanguage}
                        icon={<GlobalOutlined />}
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: '4px',
                            color: theme.secondary,
                            border: `1px solid ${theme.secondary}30`,
                            background: 'transparent',
                            borderRadius: '8px',
                            padding: '4px 12px',
                            height: 'auto'
                        }}
                    >
                        {locale === 'zh' ? 'EN' : '中文'}
                    </Button>
                </Row>
                
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Row gutter={[24, 24]} justify="center">
                        {supportCards.map((card, index) => (
                            <Col xs={24} md={8} key={index}>
                                <motion.div
                                    whileHover={{ 
                                        y: -5,
                                        boxShadow: '0 10px 25px rgba(0,0,0,0.2)' 
                                    }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <a href={card.link} target={card.link.startsWith('mailto') ? '_self' : '_blank'} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <Card 
                                            hoverable
                                            style={{
                                                background: theme.bgMedium,
                                                borderRadius: '12px',
                                                overflow: 'hidden',
                                                border: 'none',
                                                height: '100%',
                                            }}
                                            bodyStyle={{
                                                padding: '32px 24px',
                                            }}
                                        >
                                            <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                                <div style={{ 
                                                    width: '70px', 
                                                    height: '70px', 
                                                    borderRadius: '50%', 
                                                    background: `${card.color}20`,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginBottom: '16px'
                                                }}>
                                                    {card.icon}
                                                </div>
                                                
                                                <Typography.Title level={3} style={{ 
                                                    margin: '8px 0', 
                                                    color: theme.textPrimary,
                                                    fontSize: '20px'
                                                }}>
                                                    {card.title}
                                                </Typography.Title>
                                                
                                                <Typography.Text style={{ 
                                                    color: theme.textSecondary,
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    marginBottom: '8px'
                                                }}>
                                                    {card.description}
                                                </Typography.Text>
                                                
                                                {card.subText && (
                                                    <Typography.Text style={{ 
                                                        color: card.color,
                                                        fontSize: '14px'
                                                    }}>
                                                        {card.subText}
                                                    </Typography.Text>
                                                )}
                                            </Space>
                                        </Card>
                                    </a>
                                </motion.div>
                            </Col>
                        ))}
                    </Row>
                    
                    {/* 长方形联系我们卡片 */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        style={{ marginTop: '32px' }}
                    >
                        <a href="/contact" style={{ textDecoration: 'none' }}>
                            <motion.div
                                whileHover={{ 
                                    y: -3,
                                    boxShadow: '0 12px 30px rgba(0,0,0,0.25)' 
                                }}
                                transition={{ duration: 0.2 }}
                            >
                                <Card 
                                    hoverable
                                    style={{
                                        background: `linear-gradient(to right, ${theme.bgMedium} 0%, ${theme.bgLight} 100%)`,
                                        borderRadius: '12px',
                                        overflow: 'hidden',
                                        border: `1px solid ${theme.primaryLight}30`,
                                        height: '100%',
                                    }}
                                    bodyStyle={{
                                        padding: '24px 32px',
                                    }}
                                >
                                    <Row align="middle" justify="space-between">
                                        <Col>
                                            <Row align="middle" gutter={20}>
                                                <Col>
                                                    <div style={{ 
                                                        width: '60px', 
                                                        height: '60px', 
                                                        borderRadius: '50%', 
                                                        background: `linear-gradient(120deg, ${theme.primaryLight}40 0%, ${theme.primary}40 100%)`,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <CustomerServiceOutlined style={{ fontSize: 28, color: theme.primaryLight }} />
                                                    </div>
                                                </Col>
                                                
                                                <Col>
                                                    <Typography.Title level={3} style={{ 
                                                        margin: '0 0 4px 0', 
                                                        color: theme.textPrimary,
                                                        fontSize: '22px'
                                                    }}>
                                                        {locale === 'zh' ? '联系我们' : 'Contact Us'}
                                                    </Typography.Title>
                                                    
                                                    <Typography.Text style={{ 
                                                        color: theme.textSecondary,
                                                        fontSize: '15px',
                                                    }}>
                                                        {locale === 'zh' 
                                                            ? '获取更多专业支持和帮助' 
                                                            : 'Get more professional support and assistance'}
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                        <Col>
                                            <Button 
                                                type="primary" 
                                                icon={<ArrowRightOutlined />}
                                                size="large"
                                                style={{
                                                    background: theme.primary,
                                                    borderColor: theme.primary,
                                                    boxShadow: `0 6px 16px ${theme.primary}40`,
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '44px',
                                                    height: '44px',
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </motion.div>
                        </a>
                    </motion.div>
                </motion.div>

                
                <div style={{ 
                    margin: '60px auto 0',
                    textAlign: 'center',
                    color: theme.textMuted,
                    fontSize: '13px',
                    maxWidth: '600px'
                }}>
                    <p>
                        {locale === 'zh' 
                            ? '© 2025 UNDS. 保留所有权利。使用本应用即表示您同意我们的服务条款和隐私政策。' 
                            : '© 2025 UNDS. All rights reserved. By using this app, you agree to our Terms of Service and Privacy Policy.'}
                    </p>
                </div>
            </Layout.Content>
        </Layout>
    );
}

export default Support; 