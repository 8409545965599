import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Card, Typography, Button, Space, Avatar, message, Layout, Affix, Row, Col, Image } from 'antd';
import { QqOutlined, SendOutlined, CopyOutlined, HomeOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Context } from './store/Context';
import './App.css'
import socialConfig from 'config/socialConfig';

const { Title, Text, Paragraph } = Typography;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}

function isChineseLang(lang) {
  // 简体中文
  if (lang.startsWith('zh-CN') || lang.startsWith('zh-Hans')) {
    return 'zh-CN';
  }
  // 繁体中文（香港）
  if (lang.startsWith('zh-HK')) {
    return 'zh-HK';
  }
  // 繁体中文（台湾）
  if (lang.startsWith('zh-TW')) {
    return 'zh-TW';
  }
  return false;
}

function getUrlParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    autoRedirect: searchParams.get('redirect') === 'true',
    platform: searchParams.get('platform'),
    delay: parseInt(searchParams.get('delay')) || 3000,
  };
}

// 在文件顶部添加暗夜模式检测
function isDarkMode() {
  // 优先使用暗色主题
  return true;
  // 如果后续需要自动检测系统主题，可以使用下面的代码：
  // return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

// 在文件顶部添加主题常量
const THEME_CONSTANTS = {
  dark: {
    primary: '#409EFF',
    secondary: '#79bbff',
    gradient: 'linear-gradient(135deg, #409EFF 0%, #79bbff 100%)',
    cardGradient: 'linear-gradient(135deg, rgba(26, 26, 26, 0.95) 0%, rgba(13, 17, 23, 0.95) 100%)',
    borderColor: 'rgba(64,158,255,0.2)',
    shadowColor: 'rgba(64,158,255,0.15)',
  }
};

// 修改 initializeThemeStyles 函数，添加现代化主题样式
const initializeThemeStyles = () => {
  document.body.style.background = '#1a1a1a';
  
  const style = document.createElement('style');
  style.textContent = `
    body {
      background: linear-gradient(135deg, #1a1a1a 0%, #0d1117 100%);
    }

    /* 现代化背景纹理 */
    .modern-texture {
      background-image: 
        radial-gradient(circle at 50% 50%, rgba(64, 158, 255, 0.03) 0%, transparent 50%),
        radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.02) 0%, transparent 50%),
        radial-gradient(circle at 80% 80%, rgba(64, 158, 255, 0.02) 0%, transparent 50%);
      background-size: 120px 120px, 60px 60px, 90px 90px;
      opacity: 0.6;
      mix-blend-mode: soft-light;
    }

    /* 柔和的光晕效果 */
    .modern-glow {
      background: radial-gradient(circle at 50% 0%, 
        rgba(64, 158, 255, 0.15) 0%, 
        rgba(64, 158, 255, 0.05) 30%, 
        transparent 70%
      );
      filter: blur(60px);
      opacity: 0.3;
    }

    :root {
      color-scheme: dark;
      --theme-primary: #409EFF;
      --theme-secondary: #79bbff;
      --theme-gradient: linear-gradient(135deg, #409EFF 0%, #79bbff 100%);
    }

    /* 现代化卡片样式 */
    .modern-card {
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(30, 30, 30, 0.7) !important;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .modern-card:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    }

    /* 页面过渡效果 */
    .page-transition {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .page-transition.loaded {
      opacity: 1;
    }

    .ant-card-actions {
      background: rgba(255,255,255,0.04) !important;
      border-top: 1px solid rgba(255,255,255,0.1) !important;
    }
    
    .ant-card-actions > li {
      border-right: 1px solid rgba(255,255,255,0.1) !important;
    }
    
    .ant-card-actions > li:last-child {
      border-right: none !important;
    }
    
    .ant-card {
      background: rgba(30,30,30,0.9) !important;
    }

    html, body {
      overflow-x: hidden;
      width: 100%;
      position: relative;
    }
    
    #root {
      overflow-x: hidden;
      width: 100%;
    }
  `;
  document.head.appendChild(style);
};

// 在组件外执行初始化
initializeThemeStyles();

const ContactCard = () => {
  const lang = getLanguage();
  const chineseLang = isChineseLang(lang);
  const isChinese = Boolean(chineseLang);  // 用于判断是否为任意中文
  const urlParams = getUrlParams();
  const [countdown, setCountdown] = useState(Math.floor(urlParams.delay / 1000));
  const [darkMode, setDarkMode] = useState(isDarkMode());

  // 监听系统暗色模式变化
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setDarkMode(e.matches);
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  // 定义暗色主题配色
  const theme = {
    light: {
      background: 'linear-gradient(135deg, #f5f7fa 0%, #e8f5e9 100%)',
      cardBg: 'rgba(255,255,255,0.9)',
      textColor: 'rgba(0,0,0,0.85)',
      secondaryTextColor: 'rgba(0,0,0,0.45)',
      borderColor: 'rgba(0,0,0,0.05)',
      shadowColor: 'rgba(0,0,0,0.1)',
      actionsBg: 'rgba(0,0,0,0.02)',
      borderDividerColor: 'rgba(0,0,0,0.06)',
    },
    dark: {
      background: 'linear-gradient(135deg, #1a1a1a 0%, #0d1117 100%)',
      cardBg: 'rgba(30,30,30,0.9)',
      textColor: 'rgba(255,255,255,0.85)',
      secondaryTextColor: 'rgba(255,255,255,0.45)',
      borderColor: 'rgba(255,255,255,0.1)',
      shadowColor: 'rgba(0,0,0,0.5)',
      actionsBg: 'rgba(255,255,255,0.04)',
      borderDividerColor: 'rgba(255,255,255,0.1)',
    }
  };

  const currentTheme = darkMode ? theme.dark : theme.light;

  // 使用socialConfig配置
  const contactInfo = {
    qq: {
      number: socialConfig.getPlatformConfig('qq').groupNumber,
      title: isChinese ? 'QQ群' : 'QQ Group',
      mobile: socialConfig.getJoinLink('qq', true),
      desktop: socialConfig.getJoinLink('qq', false)
    },
    telegram: {
      username: socialConfig.getPlatformConfig('telegram').username,
      title: isChinese ? 'Telegram频道' : 'Telegram Channel',
      mobile: socialConfig.getJoinLink('telegram', true),
      desktop: socialConfig.getJoinLink('telegram', false)
    },
  };

  const { state } = useContext(Context);

  useEffect(() => {
    if (urlParams.autoRedirect) {
      // 倒计时效果
      const countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
          }
          return prev - 1;
        });
      }, 1000);

      // 跳转计时器
      const redirectTimer = setTimeout(() => {
        const language = isChinese ? 'zh' : 'en';
        const platform = urlParams.platform || socialConfig.getRecommendedPlatform(language);
        if (contactInfo[platform]) {
          const url = isMobile ? contactInfo[platform].mobile : contactInfo[platform].desktop;
          window.location.href = url;
        }
      }, urlParams.delay);

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(redirectTimer);
      };
    }
  }, []);

  const copyToClipboard = (text) => {
    // 尝试使用现代 Clipboard API
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => {
        message.success({
          content: isChinese ? '已复制' : 'Copied',
          icon: <CheckOutlined style={{ color: '#52c41a' }} />,
          className: 'custom-message',
          duration: 1.5,
        });
      });
    } else {
      // 后备方案：使用传统的复制方法
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        message.success({
          content: isChinese ? '已复制' : 'Copied',
          icon: <CheckOutlined style={{ color: '#52c41a' }} />,
          className: 'custom-message',
          duration: 1.5,
        });
      } catch (err) {
        message.error({
          content: isChinese ? '复制失败，请手动复制' : 'Copy failed, please copy manually',
          duration: 2,
        });
      }

      document.body.removeChild(textArea);
    }
  };

  const redirect = (platform) => {
    const url = isMobile ? contactInfo[platform].mobile : contactInfo[platform].desktop;
    window.location.href = url;
  };

  const AutoRedirectTip = () => {
    if (!urlParams.autoRedirect || countdown <= 0) return null;
    
    return (
      <div style={{
        textAlign: 'center',
        padding: '12px',
        background: darkMode ? 'rgba(64,158,255,0.15)' : 'rgba(24,144,255,0.1)',
        borderRadius: '8px',
        marginBottom: '20px',
        border: darkMode ? '1px solid rgba(64,158,255,0.3)' : '1px solid rgba(24,144,255,0.2)',
        animation: 'breathe 2s infinite ease-in-out'
      }}>
        <Space>
          <div style={{ 
            display: 'inline-block', 
            width: '20px',
            color: darkMode ? '#409EFF' : '#1890ff',
            fontWeight: 'bold'
          }}>
            {countdown}
          </div>
          <Text style={{ 
            color: darkMode ? 'rgba(255,255,255,0.85)' : 'rgba(0,0,0,0.65)'
          }}>
            {isChinese 
              ? '秒后将自动为您跳转到推荐平台...' 
              : 'seconds until auto-redirect to recommended platform...'}
          </Text>
        </Space>
      </div>
    );
  };

  const getGroupDescription = (platform) => {
    return '';
  };

  const createContactCards = () => {
    const sections = [
      {
        platform: 'qq',
        number: contactInfo.qq.number,
        description: isChinese ? 
          '加入我们的 QQ 群，获取最新资源和技术支持' : 
          'Join our QQ Group for resources and support'
      },
      {
        platform: 'telegram',
        number: contactInfo.telegram.username,
        description: isChinese ? 
          '关注我们的 Telegram 频道，获取实时更新' : 
          'Follow our Telegram channel for real-time updates'
      }
    ];

    // 中文环境推荐 QQ，其他语言环境推荐 Telegram
    return isChinese ? sections : sections.reverse();
  };

  // 将 PlatformSection 移到 return 语句之前
  const PlatformSection = ({ platform, number, description }) => {
    // 判断是否为推荐平台（根据语言环境）
    const language = isChinese ? 'zh' : 'en';
    const recommendedPlatform = socialConfig.getRecommendedPlatform(language);
    const isQQ = platform === 'qq';
    const isRecommended = platform === recommendedPlatform;
    
    const colors = isQQ ? {
      primary: '#00d2ff',
      secondary: '#3a7bd5',
    } : {
      primary: '#6a11cb',
      secondary: '#2575fc',
    };

    return (
      <div style={{
        background: darkMode ? '#1a1a1a' : '#ffffff',
        borderRadius: '16px',
        padding: '24px',
        border: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
        position: 'relative', // 添加相对定位
      }}>
        {/* 推荐标签 */}
        {isRecommended && (
          <div style={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            background: 'linear-gradient(135deg, #ff4d4f 0%, #ff7875 100%)',
            padding: '4px 12px',
            borderRadius: '12px',
            fontSize: '12px',
            color: '#fff',
            fontWeight: '500',
            boxShadow: '0 2px 8px rgba(255,77,79,0.3)',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}>
            <CheckOutlined style={{ fontSize: '12px' }} />
            {isChinese ? '推荐平台' : 'Recommended'}
          </div>
        )}

        {/* 平台名称 */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          marginBottom: '20px',
        }}>
          {isQQ ? 
            <QqOutlined style={{ fontSize: '24px', color: colors.primary }} /> :
            <SendOutlined style={{ fontSize: '24px', color: colors.primary }} />
          }
          <Text strong style={{
            fontSize: '18px',
            color: darkMode ? '#ffffff' : '#000000',
          }}>
            {isQQ ? 
              (isChinese ? 'QQ群' : 'QQ Group') : 
              'Telegram'
            }
          </Text>
        </div>

        {/* 群号/频道信息（重点突出） */}
        <div style={{
          background: darkMode ? '#000000' : '#f8f9fa',
          padding: '20px',
          borderRadius: '12px',
          marginBottom: '20px',
          border: `1px solid ${darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.06)'}`,
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <div>
              <Text style={{
                fontSize: '14px',
                color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.45)',
                display: 'block',
                marginBottom: '4px',
              }}>
                {isQQ ? 
                  (isChinese ? '群号' : 'Group ID') : 
                  (isChinese ? '频道' : 'Channel')
                }
              </Text>
              <Text 
                style={{
                  fontSize: '32px',
                  fontFamily: isQQ ? 'monospace' : 'inherit',
                  fontWeight: '600',
                  color: colors.primary,
                  letterSpacing: isQQ ? '1px' : 'normal',
                  cursor: 'copy',
                  userSelect: 'none', // 防止文本被选中
                }}
                onClick={() => copyToClipboard(number)}
              >
                {number}
              </Text>
            </div>
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(number)}
              style={{
                color: colors.primary,
              }}
            />
          </div>
        </div>

        {/* 加入按钮 */}
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={() => redirect(platform)}
          style={{
            width: '100%',
            height: '44px',
            background: `linear-gradient(to right, ${colors.primary}, ${colors.secondary})`,
            border: 'none',
            borderRadius: '8px',
            fontSize: '16px',
          }}
        >
          {isQQ ? 
            (isChinese ? '加入QQ群' : 'Join QQ Group') : 
            (isChinese ? '加入Telegram' : 'Join Telegram')
          }
        </Button>
      </div>
    );
  };

  // 更新主题变量的函数
  const updateThemeVariables = () => {
    document.documentElement.style.setProperty(
      '--card-actions-bg', 
      darkMode ? theme.dark.actionsBg : theme.light.actionsBg
    );
    document.documentElement.style.setProperty(
      '--card-border-divider', 
      darkMode ? theme.dark.borderDividerColor : theme.light.borderDividerColor
    );
    document.documentElement.style.setProperty(
      '--card-bg', 
      darkMode ? theme.dark.cardBg : theme.light.cardBg
    );
  };

  // 在 darkMode 改变时更新变量
  useEffect(() => {
    updateThemeVariables();
  }, [darkMode]);

  // 移除原来的样式注入代码
  const customStyles = `
    .custom-message {
      animation: slideIn 0.3s ease-out;
    }
    @keyframes slideIn {
      from { transform: translateY(10px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
  `;

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = customStyles;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  // 添加页面加载状态
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    // 页面加载完成后设置状态
    setPageLoaded(true);
  }, []);

  return (
    <Layout style={{
      minHeight: '100vh',
      background: darkMode ? 
        'linear-gradient(135deg, #1a1a1a 0%, #0d1117 100%)' :
        'linear-gradient(135deg, #f0f2f5 0%, #e6e9ec 100%)',
    }}>
      {/* 基础背景纹理 */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
          radial-gradient(circle at 20% 20%, rgba(255,255,255,0.03) 0%, transparent 70%),
          radial-gradient(circle at 80% 80%, rgba(255,255,255,0.02) 0%, transparent 70%)
        `,
        opacity: 0.6,
        pointerEvents: 'none',
        zIndex: 0,
      }} />

      <Affix offsetTop={0}>
        <Layout.Header style={{ 
          background: 'rgba(13,17,23,0.85)',
          backdropFilter: 'blur(20px)',
          WebkitBackdropFilter: 'blur(20px)',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
          height: '64px',
          lineHeight: '64px',
          padding: '0 24px',
          zIndex: 1000,
        }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
              <Space>
                <Image
                  height={isMobile ? 24 : 30}
                  preview={false}
                  src={state.appLogo}
                  style={{
                    objectFit: 'contain',
                    width: 'auto',
                    maxHeight: '100%',
                    filter: 'brightness(1.2)', // 增加 logo 亮度
                  }}
                />
                <Typography.Title level={3} style={{ 
                  margin: 0,
                  color: 'rgba(255,255,255,0.95)', // 增加标题文字亮度
                  textShadow: '0 2px 4px rgba(0,0,0,0.2)', // 添加文字阴影
                }}>
                  {state.appName}
                </Typography.Title>
              </Space>
            </Col>
          </Row>
        </Layout.Header>
      </Affix>

      <Layout.Content style={{
        padding: '48px 24px',
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%',
      }}>
        {/* 添加自动跳转提示 */}
        <AutoRedirectTip />

        {/* 页面标题 */}
        <div style={{
          textAlign: 'center',
          marginBottom: '48px',
        }}>
          <Title level={1} style={{
            fontSize: '32px',
            fontWeight: '700',
            background: 'linear-gradient(135deg, #ff4d4f 0%, #ff7875 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '16px',
            whiteSpace: 'nowrap',
          }}>
            {isChinese ? '找资源？来这就对了' : 'Looking for Resources?'}
          </Title>
          <Text style={{
            fontSize: '18px',
            color: darkMode ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,0.65)',
          }}>
            {isChinese ? 
              '有问题随时来群里问，大家都很热心' : 
              'Feel free to ask questions, we are here to help'
            }
          </Text>
        </div>

        {/* 联系方式部分 */}
        <Row gutter={[32, 32]}>
          {createContactCards().map((section, index) => (
            <Col xs={24} md={12} key={index}>
              <PlatformSection {...section} />
            </Col>
          ))}
        </Row>

        {/* 返回首页按钮 */}
        <div style={{
          textAlign: 'center',
          marginTop: '48px',
        }}>
          <Button 
            icon={<HomeOutlined />}
            onClick={() => window.location.href = '/'} 
            style={{
              height: '48px',
              padding: '0 32px',
              borderRadius: '12px',
              background: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
              borderColor: darkMode ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.06)',
              color: darkMode ? 'rgba(255,255,255,0.85)' : 'rgba(0,0,0,0.85)',
            }}
          >
            {isChinese ? '返回首页' : 'Back to Home'}
          </Button>
        </div>
      </Layout.Content>

      <Layout.Footer style={{ 
        textAlign: 'center', 
        background: 'rgba(13,17,23,0.85)',
        backdropFilter: 'blur(20px)',
        WebkitBackdropFilter: 'blur(20px)',
        padding: '32px 24px',
        borderTop: '1px solid rgba(255,255,255,0.1)',
        position: 'relative',
        zIndex: 1,
      }}>
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {state.appName} © {new Date().getFullYear()}
          </Text>
          <div style={{ 
            fontSize: '12px', 
            color: 'rgba(255,255,255,0.65)',
            maxWidth: '600px',
            margin: '0 auto',
            padding: '0 16px',
            lineHeight: '1.8',
          }}>
            <Text type="secondary" style={{ 
              color: 'inherit'
            }}>
              {isChinese 
                ? '本社区仅供用户交流，内容均由用户分享。加入即视为同意遵守社区规范。' 
                : 'This community is for communication only. Content is shared by users. By joining, you agree to follow community guidelines.'}
            </Text>
            <div style={{ 
              marginTop: '12px',
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              flexWrap: 'wrap',
            }}>
              <a 
                href="/policy/terms" 
                style={{ 
                  color: 'inherit',
                  textDecoration: 'underline',
                  opacity: 0.8,
                }}
              >
                {isChinese ? '服务条款' : 'Terms of Service'}
              </a>
              <Text type="secondary" style={{ color: 'inherit' }}>·</Text>
              <a 
                href="/policy/privacy" 
                style={{ 
                  color: 'inherit',
                  textDecoration: 'underline',
                  opacity: 0.8,
                }}
              >
                {isChinese ? '隐私政策' : 'Privacy Policy'}
              </a>
              <Text type="secondary" style={{ color: 'inherit' }}>·</Text>
              <a 
                href="mailto:report@hiappstore.com" 
                style={{ 
                  color: 'inherit',
                  textDecoration: 'underline',
                  opacity: 0.8,
                }}
              >
                {isChinese ? '投诉建议' : 'Report Issues'}
              </a>
            </div>
          </div>
        </Space>
      </Layout.Footer>
    </Layout>
  );
};

export default ContactCard;
