// @Tutorial.js
// Unds: Tutorial Component
import React, { useState, useEffect } from 'react';
import { Layout, Button, Typography, Row, Col, Space, Tabs, Divider, Alert, Card } from 'antd';
import { motion } from 'framer-motion';
import { Context } from './store/Context';
import { useContext } from 'react';
import { ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { getLocale, getBrowserLanguage } from './locales';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

// 游戏模拟器主题
const theme = {
  // 主色调 - 紫色系
  primary: '#6c5ce7',
  primaryLight: '#a29bfe',
  primaryDark: '#5341d8',
  
  // 次要色 - 青色系
  secondary: '#00cec9',
  secondaryLight: '#81ecec',
  secondaryDark: '#00b5b0',
  
  // 强调色 - 加入一点橙色元素
  accent: '#fd79a8',
  accentLight: '#fab1a0',
  
  // 背景色和文本色
  bgDark: '#1e1e2e',
  bgMedium: '#2d2d3f',
  bgLight: '#393a58',
  textPrimary: '#ffffff',
  textSecondary: '#d0d0d7',
  textMuted: '#a0a0b0',
  
  // 渐变色
  gradientPrimary: 'linear-gradient(120deg, #6c5ce7 0%, #5341d8 100%)',
  gradientSecondary: 'linear-gradient(120deg, #00cec9 0%, #00b5b0 100%)',
  gradientAccent: 'linear-gradient(120deg, #fd79a8 0%, #fab1a0 100%)',
  gradientBackground: 'linear-gradient(150deg, #1e1e2e 0%, #2d2d3f 100%)'
};

const Tutorial = () => {
  const { state } = useContext(Context);
  const [locale, setLocale] = useState(getBrowserLanguage());
  const [videoError, setVideoError] = useState(false);
  const localeText = getLocale(locale);

  useEffect(() => {
    // Add some smooth page entrance animation
    document.body.style.overflowX = 'hidden';
    document.body.style.background = theme.bgDark;
    
    return () => {
      document.body.style.background = '';
      document.body.style.overflowX = '';
    };
  }, []);

  const toggleLanguage = () => {
    setLocale(locale === 'en' ? 'zh' : 'en');
    setVideoError(false);
  };

  const handleVideoError = (e) => {
    console.log('Video loading error:', e);
    setVideoError(true);
  };

  const renderVideoContent = () => {
    if (videoError) {
      return (
        <Alert
          message={locale === 'zh' ? "视频加载失败" : "Video Loading Failed"}
          description={locale === 'zh' ? "抱歉，视频暂时无法播放。请稍后再试。" : "Sorry, the video cannot be played right now. Please try again later."}
          type="error"
          showIcon
          style={{ 
            maxWidth: '800px', 
            margin: '20px auto',
            borderRadius: '8px',
            background: theme.bgLight,
            borderColor: 'rgba(255,77,79,0.2)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }}
        />
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Row justify="center">
          <Col xs={24} md={20} lg={16}>
            <div style={{ 
              maxWidth: '800px',
              margin: '20px auto',
              borderRadius: '12px',
              overflow: 'hidden',
              background: theme.bgMedium,
              boxShadow: '0 8px 24px rgba(0,0,0,0.2)'
            }}>
              <video
                style={{
                  width: '100%',
                  maxWidth: '800px',
                  borderRadius: '8px 8px 0 0',
                  display: 'block'
                }}
                src={locale === 'zh' ? "/videos/unds_tutorial_cn.mp4" : "/videos/unds_tutorial_cn.mp4"}
                controls
                autoPlay
                muted
                playsInline
                onError={handleVideoError}
              >
              </video>

              <div style={{
                padding: '24px',
                background: theme.bgMedium,
                borderRadius: '0 0 12px 12px'
              }}>
                <Title level={4} style={{ 
                  marginBottom: '20px', 
                  color: theme.textPrimary,
                  fontSize: '20px',
                  fontWeight: 600 
                }}>
                  {locale === 'zh' ? "操作步骤" : "Instructions"}
                </Title>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                  <div style={{
                    padding: '16px',
                    background: 'rgba(108, 92, 231, 0.1)',
                    borderLeft: `3px solid ${theme.primary}`,
                    borderRadius: '4px'
                  }}>
                    <Text strong style={{ color: theme.textPrimary, display: 'block', marginBottom: '8px' }}>
                      1. {locale === 'zh' ? "打开文件管理" : "Open File Manager"}
                    </Text>
                    <Text style={{ color: theme.textSecondary }}>
                      {locale === 'zh' 
                        ? "在应用底部导航栏中点击「文件」选项。" 
                        : "Click the 'Files' option in the bottom navigation bar."}
                    </Text>
                  </div>
                  
                  <div style={{
                    padding: '16px',
                    background: 'rgba(0, 206, 201, 0.1)',
                    borderLeft: `3px solid ${theme.secondary}`,
                    borderRadius: '4px'
                  }}>
                    <Text strong style={{ color: theme.textPrimary, display: 'block', marginBottom: '8px' }}>
                      2. {locale === 'zh' ? "导入ROM文件" : "Import ROM File"}
                    </Text>
                    <Text style={{ color: theme.textSecondary }}>
                      {locale === 'zh' 
                        ? "点击右上角的「+」按钮，从您的设备中选择ROM文件。" 
                        : "Tap the add button in the top right corner to select a ROM file from your device."}
                    </Text>
                  </div>
                  
                  <div style={{
                    padding: '16px',
                    background: 'rgba(253, 121, 168, 0.1)',
                    borderLeft: `3px solid ${theme.accent}`,
                    borderRadius: '4px'
                  }}>
                    <Text strong style={{ color: theme.textPrimary, display: 'block', marginBottom: '8px' }}>
                      3. {locale === 'zh' ? "等待导入" : "Wait for Import"}
                    </Text>
                    <Text style={{ color: theme.textSecondary }}>
                      {locale === 'zh' 
                        ? "系统会自动处理并导入您选择的ROM文件。" 
                        : "The system will automatically process and import your selected ROM file."}
                    </Text>
                  </div>
                  
                  <div style={{
                    padding: '16px',
                    background: 'rgba(108, 92, 231, 0.1)',
                    borderLeft: `3px solid ${theme.primary}`,
                    borderRadius: '4px'
                  }}>
                    <Text strong style={{ color: theme.textPrimary, display: 'block', marginBottom: '8px' }}>
                      4. {locale === 'zh' ? "开始游戏" : "Start Playing"}
                    </Text>
                    <Text style={{ color: theme.textSecondary }}>
                      {locale === 'zh' 
                        ? "导入完成后，返回主页即可看到并开始游戏。" 
                        : "Once imported, return to the home page to see and start your game."}
                    </Text>
                  </div>
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </motion.div>
    );
  };

  return (
    <Layout style={{ 
      minHeight: '100vh',
      background: theme.bgDark,
      color: theme.textPrimary,
      overflowX: 'hidden'
    }}>
      <Content style={{ 
        padding: '40px 24px',
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%'
      }}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Row 
            justify="space-between" 
            align="middle" 
            style={{ 
              marginBottom: '32px',
              flexWrap: 'nowrap',
              width: '100%'
            }}
          >
            <Title 
              level={2} 
              style={{ 
                margin: 0,
                fontSize: 'clamp(1.5rem, 1.2rem + 1.2vw, 2.2rem)',
                flexShrink: 1,
                color: theme.textPrimary,
                fontWeight: 700
              }}
            >
              {locale === 'zh' ? '使用教程' : 'Tutorial'}
            </Title>
            <Button 
              type="text" 
              onClick={toggleLanguage}
              icon={<GlobalOutlined />}
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '4px',
                color: theme.secondary,
                border: `1px solid ${theme.secondary}30`,
                background: 'transparent',
                borderRadius: '8px',
                padding: '4px 12px',
                height: 'auto'
              }}
            >
              {locale === 'zh' ? 'EN' : '中文'}
            </Button>
          </Row>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <Row style={{
            padding: '16px 20px',
            background: 'rgba(246, 133, 18, 0.1)',
            border: '1px solid rgba(246, 133, 18, 0.2)',
            borderRadius: '12px',
            marginBottom: '32px',
            width: '100%'
          }}>
            <Col flex="auto">
              <Space align="start" style={{ width: '100%' }}>
                <ExclamationCircleOutlined style={{ 
                  color: '#f39c12', 
                  fontSize: '20px', 
                  marginTop: '3px',
                  flexShrink: 0
                }} />
                <Text style={{ 
                  color: theme.textSecondary,
                  fontSize: '14px',
                  lineHeight: '1.6',
                  wordBreak: 'break-word'
                }}>
                  {locale === 'zh' ? (
                    '请确保使用合法ROM，非法获取可能违反法律。'
                  ) : (
                    'Please ensure legal ROM usage. Illegal acquisition may violate laws.'
                  )}
                </Text>
              </Space>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Tabs 
            defaultActiveKey="1" 
            style={{ marginBottom: '40px' }}
            tabBarStyle={{ 
              borderBottom: `1px solid ${theme.bgLight}`,
              color: theme.textSecondary
            }}
          >
            <TabPane 
              tab={
                <span style={{ color: theme.textSecondary, padding: '0 8px' }}>
                  {locale === 'zh' ? "添加游戏" : "Add Game"}
                </span>
              } 
              key="1"
            >
              {renderVideoContent()}
            </TabPane>
            <TabPane 
              tab={
                <span style={{ color: theme.textSecondary, padding: '0 8px' }}>
                  {locale === 'zh' ? "金手指" : "Cheat Codes"}
                </span>
              } 
              key="2"
            >
              <Alert
                message={locale === 'zh' ? "即将推出" : "Coming Soon"}
                description={locale === 'zh' ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
                type="info"
                showIcon
                style={{ 
                  maxWidth: '800px', 
                  margin: '20px auto',
                  borderRadius: '12px',
                  background: theme.bgMedium,
                  borderColor: 'rgba(24,144,255,0.2)',
                  color: theme.textSecondary
                }}
              />
            </TabPane>
            <TabPane 
              tab={
                <span style={{ color: theme.textSecondary, padding: '0 8px' }}>
                  {locale === 'zh' ? "存档管理" : "Save Files"}
                </span>
              } 
              key="3"
            >
              <Alert
                message={locale === 'zh' ? "即将推出" : "Coming Soon"}
                description={locale === 'zh' ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
                type="info"
                showIcon
                style={{ 
                  maxWidth: '800px', 
                  margin: '20px auto',
                  borderRadius: '12px',
                  background: theme.bgMedium,
                  borderColor: 'rgba(24,144,255,0.2)',
                  color: theme.textSecondary
                }}
              />
            </TabPane>
            <TabPane 
              tab={
                <span style={{ color: theme.textSecondary, padding: '0 8px' }}>
                  {locale === 'zh' ? "屏幕旋转" : "Screen Rotation"}
                </span>
              } 
              key="4"
            >
              <Alert
                message={locale === 'zh' ? "即将推出" : "Coming Soon"}
                description={locale === 'zh' ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
                type="info"
                showIcon
                style={{ 
                  maxWidth: '800px', 
                  margin: '20px auto',
                  borderRadius: '12px',
                  background: theme.bgMedium,
                  borderColor: 'rgba(24,144,255,0.2)',
                  color: theme.textSecondary
                }}
              />
            </TabPane>
          </Tabs>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <Row justify="center" style={{ marginTop: '40px' }}>
            <Col xs={24} sm={20} md={16}>
              <Space direction="vertical" style={{ width: '100%' }} size="large">
                <div style={{ textAlign: 'center' }}>
                  <Space direction="vertical" size="small">
                    <Text style={{ color: theme.textSecondary, fontSize: '16px' }}>
                      {locale === 'zh' ? '如果您有任何疑问，请联系我们' : 'If you have any questions, please contact us'}
                    </Text>
                    <Button 
                      type="primary" 
                      onClick={() => window.location.href = '/contact'}
                      style={{
                        background: theme.primary,
                        borderColor: theme.primary,
                        marginTop: '16px',
                        height: '40px',
                        borderRadius: '8px',
                        fontWeight: 500
                      }}
                    >
                      {localeText.common.support}
                    </Button>
                  </Space>
                </div>

                <div style={{ 
                  fontSize: '13px', 
                  color: theme.textMuted, 
                  padding: '20px', 
                  background: theme.bgMedium, 
                  borderRadius: '12px',
                  textAlign: 'center',
                  marginTop: '32px'
                }}>
                  {locale === 'zh' ? (
                    <>
                      使用本应用前，请确保您已同意我们的<a href="/policy/terms" style={{ color: theme.secondary }}>服务条款</a>和<a href="/policy/privacy" style={{ color: theme.secondary }}>隐私条款</a>。
                      如发现违规行为，请发送邮件至 <a href="mailto:report@hiappstore.com" style={{ color: theme.secondary }}>report@hiappstore.com</a>
                    </>
                  ) : (
                    <>
                      Before using this app, please ensure you agree to our <a href="/policy/terms" style={{ color: theme.secondary }}>Terms of Service</a> and <a href="/policy/privacy" style={{ color: theme.secondary }}>Privacy Policy</a>.
                      To report violations, please email <a href="mailto:report@hiappstore.com" style={{ color: theme.secondary }}>report@hiappstore.com</a>
                    </>
                  )}
                </div>
              </Space>
            </Col>
          </Row>
        </motion.div>
      </Content>
    </Layout>
  );
};

export default Tutorial; 