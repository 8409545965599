import React, { useEffect, useState } from 'react';
import { getBrowserLanguage } from '../locales';
import { Spin, Typography } from 'antd';
import { DownloadOutlined, AppleOutlined } from '@ant-design/icons';

const Download = () => {
  const [isRedirecting, setIsRedirecting] = useState(true);
  
  useEffect(() => {
    // 获取用户浏览器语言和地区
    const userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    
    // 基础 App Store ID
    const appId = 'id6737550290';
    
    // 从语言代码中提取地区信息
    let region = 'us'; // 默认美国区域
    let language = 'en-us'; // 默认英语
    
    // 根据语言代码映射到 App Store 地区和语言
    if (userLanguage.includes('zh')) {
      if (userLanguage.includes('CN') || userLanguage.includes('Hans')) {
        region = 'cn';
        language = 'zh-cn';
      } else if (userLanguage.includes('TW') || userLanguage.includes('HK') || userLanguage.includes('Hant')) {
        region = 'tw';
        language = 'zh-tw';
      }
    } else if (userLanguage.includes('ja')) {
      region = 'jp';
      language = 'ja';
    } else if (userLanguage.includes('ko')) {
      region = 'kr';
      language = 'ko';
    } else if (userLanguage.includes('fr')) {
      region = 'fr';
      language = 'fr';
    } else if (userLanguage.includes('de')) {
      region = 'de';
      language = 'de';
    } else if (userLanguage.includes('es')) {
      if (userLanguage.includes('ES')) {
        region = 'es';
      } else if (userLanguage.includes('MX')) {
        region = 'mx';
      } else {
        region = 'es';
      }
      language = 'es';
    } else if (userLanguage.includes('it')) {
      region = 'it';
      language = 'it';
    } else if (userLanguage.includes('pt')) {
      if (userLanguage.includes('BR')) {
        region = 'br';
      } else {
        region = 'pt';
      }
      language = 'pt';
    } else if (userLanguage.includes('ru')) {
      region = 'ru';
      language = 'ru';
    } else if (userLanguage.includes('nl')) {
      region = 'nl';
      language = 'nl';
    } else if (userLanguage.includes('en')) {
      if (userLanguage.includes('GB')) {
        region = 'gb';
      } else if (userLanguage.includes('AU')) {
        region = 'au';
      } else if (userLanguage.includes('CA')) {
        region = 'ca';
      } else {
        region = 'us';
      }
      language = 'en-us';
    }
    
    // 构建 App Store URL
    const redirectUrl = `https://apps.apple.com/${region}/app/unds-ds-gba-md-game-emulator/${appId}?l=${language}`;
    
    // 设置延迟，显示加载动画
    const timer = setTimeout(() => {
      // 重定向到 App Store
      window.location.href = redirectUrl;
      setIsRedirecting(false);
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);
  
  const getLoadingText = () => {
    const language = navigator.language || navigator.userLanguage;
    if (language.includes('zh')) {
      if (language.includes('TW') || language.includes('HK') || language.includes('Hant')) {
        return '正在跳轉到 App Store...';
      }
      return '正在跳转到 App Store...';
    } else if (language.includes('ja')) {
      return 'App Store に転送しています...';
    } else if (language.includes('ko')) {
      return 'App Store로 리디렉션 중...';
    } else if (language.includes('fr')) {
      return 'Redirection vers l\'App Store...';
    } else if (language.includes('de')) {
      return 'Weiterleitung zum App Store...';
    } else if (language.includes('es')) {
      return 'Redirigiendo a la App Store...';
    } else if (language.includes('it')) {
      return 'Reindirizzamento all\'App Store...';
    } else if (language.includes('ru')) {
      return 'Перенаправление в App Store...';
    } else if (language.includes('pt')) {
      return 'Redirecionando para a App Store...';
    } else {
      return 'Redirecting to App Store...';
    }
  };
  
  const getManualText = () => {
    const language = navigator.language || navigator.userLanguage;
    if (language.includes('zh')) {
      if (language.includes('TW') || language.includes('HK') || language.includes('Hant')) {
        return '如果未自動跳轉，請點擊此處';
      }
      return '如果未自动跳转，请点击此处';
    } else if (language.includes('ja')) {
      return '自動的にリダイレクトされない場合は、ここをクリックしてください';
    } else if (language.includes('ko')) {
      return '자동으로 리디렉션되지 않으면 여기를 클릭하세요';
    } else if (language.includes('fr')) {
      return 'Si vous n\'êtes pas redirigé automatiquement, cliquez ici';
    } else if (language.includes('de')) {
      return 'Wenn Sie nicht automatisch weitergeleitet werden, klicken Sie hier';
    } else if (language.includes('es')) {
      return 'Si no es redirigido automáticamente, haga clic aquí';
    } else if (language.includes('it')) {
      return 'Se non vieni reindirizzato automaticamente, clicca qui';
    } else if (language.includes('ru')) {
      return 'Если вы не были перенаправлены автоматически, нажмите здесь';
    } else if (language.includes('pt')) {
      return 'Se não for redirecionado automaticamente, clique aqui';
    } else {
      return 'If you are not redirected automatically, click here';
    }
  };
  
  // 获取当前用户的区域和语言，用于手动链接
  const getManualLink = () => {
    const userLanguage = navigator.language || navigator.userLanguage || 'en-US';
    let region = 'us';
    let language = 'en-us';
    
    if (userLanguage.includes('zh')) {
      if (userLanguage.includes('CN') || userLanguage.includes('Hans')) {
        region = 'cn';
        language = 'zh-cn';
      } else if (userLanguage.includes('TW') || userLanguage.includes('HK') || userLanguage.includes('Hant')) {
        region = 'tw';
        language = 'zh-tw';
      }
    } else if (userLanguage.includes('ja')) {
      region = 'jp';
      language = 'ja';
    } else if (userLanguage.includes('ko')) {
      region = 'kr';
      language = 'ko';
    } else if (userLanguage.includes('fr')) {
      region = 'fr';
      language = 'fr';
    } else if (userLanguage.includes('de')) {
      region = 'de';
      language = 'de';
    } else if (userLanguage.includes('es')) {
      region = userLanguage.includes('MX') ? 'mx' : 'es';
      language = 'es';
    } else if (userLanguage.includes('it')) {
      region = 'it';
      language = 'it';
    } else if (userLanguage.includes('pt')) {
      region = userLanguage.includes('BR') ? 'br' : 'pt';
      language = 'pt';
    } else if (userLanguage.includes('ru')) {
      region = 'ru';
      language = 'ru';
    } else if (userLanguage.includes('en')) {
      if (userLanguage.includes('GB')) {
        region = 'gb';
      } else if (userLanguage.includes('AU')) {
        region = 'au';
      } else if (userLanguage.includes('CA')) {
        region = 'ca';
      }
    }
    
    return `https://apps.apple.com/${region}/app/unds-ds-gba-md-game-emulator/id6737550290?l=${language}`;
  };
  
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      flexDirection: 'column',
      padding: '20px',
      textAlign: 'center',
      background: 'linear-gradient(135deg, #0a1930, #1f3a60)',
      color: '#ffffff'
    }}>
      <AppleOutlined style={{ fontSize: '48px', marginBottom: '16px', color: '#ffffff' }} />
      <Typography.Title level={2} style={{ color: '#ffffff', marginBottom: '16px' }}>
        {getLoadingText()}
      </Typography.Title>
      
      {isRedirecting && (
        <Spin size="large" style={{ marginBottom: '24px' }} />
      )}
      
      <div style={{ 
        background: 'rgba(255, 255, 255, 0.1)', 
        padding: '16px 24px', 
        borderRadius: '8px',
        marginTop: '24px',
        backdropFilter: 'blur(5px)'
      }}>
        <p style={{ margin: 0 }}>
          <a 
            href={getManualLink()} 
            style={{ color: '#4da3ff', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}
          >
            <DownloadOutlined /> {getManualText()}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Download;