// 本地化文本配置文件
// 包含所有组件可能用到的多语言文本

const locales = {
  // 英文本地化
  en: {
    common: {
      support: "Support",
      download: "Download",
      learnMore: "Learn More",
      allRightsReserved: "All Rights Reserved."
    },
    desktop: {
      multipleConsoleEmulator: "Multiple Console Emulator",
      joinOurCommunity: "Join our community",
      communityDescription: "Connect with other retro gaming enthusiasts, share your experiences, and get help with your favorite games.",
      telegramCommunity: "Telegram Community",
      qqCommunity: "QQ Group",
      supportedPlatforms: "Supported platforms",
      andMore: "And more...",
      highPerformance: "High Performance",
      multiPlatform: "Multi-Platform",
      saveStates: "Save States",
      highPerformanceDesc: "Fast and smooth emulation experience for all your games",
      multiPlatformDesc: "Support for various classic gaming consoles",
      saveStatesDesc: "Save and resume your gameplay whenever you want",
      classicHandheldEmulation: "Classic Handheld Emulation",
      dualScreenGames: "Dual-Screen Games",
      highPerformanceEmulation: "High Performance",
      saveStatesSupport: "Save States",
      languageSwitcher: "Switch language",
      endorsementTitle: "Experience classic games on your favorite emulator",
      endorsementText: "UNDS emulator supports various classic gaming platforms, allowing you to relive your childhood gaming fun.",
      endorsementDesc: "Please ensure to use legally obtained game files."
    },
    mobile: {
      downloadNow: "Download Now",
      features: "Features",
      multiplatform: "Multi-platform Support"
    },
    faq: {
      title: "Frequently Asked Questions",
      searchPlaceholder: "Search for questions..."
    },
    thanksPage: {
      title: "Thank You",
      mainTitle: "Thank You for Your Support",
      subtitle: "We appreciate your trust and support for our application.",
      contactUs: "If you have any questions or suggestions, please don't hesitate to reach out.",
      contactButton: "Contact Us"
    },
    footer: {
      rights: "All Rights Reserved."
    }
  },
  
  // 中文本地化
  zh: {
    common: {
      support: "联系我们",
      download: "立即下载",
      learnMore: "了解更多",
      allRightsReserved: "保留所有权利。"
    },
    desktop: {
      multipleConsoleEmulator: "多合一游戏模拟器",
      joinOurCommunity: "加入我们的社区",
      communityDescription: "与其他复古游戏爱好者联系，分享您的经验，并获得您最喜爱的游戏的帮助。",
      telegramCommunity: "Telegram 社区",
      qqCommunity: "QQ 社区群",
      supportedPlatforms: "支持的平台",
      andMore: "更多平台...",
      highPerformance: "高性能",
      multiPlatform: "多平台",
      saveStates: "存档功能",
      highPerformanceDesc: "为所有游戏提供快速流畅的模拟体验",
      multiPlatformDesc: "支持多种经典游戏主机",
      saveStatesDesc: "随时保存和恢复您的游戏进度",
      classicHandheldEmulation: "经典掌机模拟",
      dualScreenGames: "双屏游戏支持",
      highPerformanceEmulation: "高性能模拟",
      saveStatesSupport: "存档/读档支持",
      languageSwitcher: "切换语言",
      endorsementTitle: "在您喜爱的模拟器上体验经典游戏",
      endorsementText: "UNDS模拟器支持多种经典游戏平台，让您重温童年的游戏乐趣。",
      endorsementDesc: "请确保使用合法获取的游戏文件。"
    },
    mobile: {
      downloadNow: "立即下载",
      features: "主要特点",
      multiplatform: "多平台支持"
    },
    faq: {
      title: "常见问题解答",
      searchPlaceholder: "搜索问题..."
    },
    thanksPage: {
      title: "感谢",
      mainTitle: "感谢您的支持",
      subtitle: "感谢您对我们应用的信任和支持。",
      contactUs: "如果您有任何问题或建议，请随时与我们联系。",
      contactButton: "联系我们"
    },
    footer: {
      rights: "保留所有权利。"
    }
  }
};

// 获取当前浏览器语言
export const getBrowserLanguage = () => {
  const browserLang = navigator.language.toLowerCase();
  return browserLang.includes('zh') ? 'zh' : 'en';
};

// 获取本地化文本
export const getLocale = (lang = getBrowserLanguage()) => {
  return locales[lang] || locales.en;
};

export default locales; 