/** 
 * 社交平台配置文件
 * 全局配置，可在任何组件中导入使用
 */

// 社交平台基础配置
const socialPlatforms = { 
  // QQ群配置
  qq: {
    groupNumber: '176203805',
    groupName: 'UNDS掌机模拟器交流群',
    joinLink: {
      mobile: "https://qm.qq.com/cgi-bin/qm/qr?k=8sLZ-Hitjhcx9QJZXerKY0O5f0IMQ1v8&jump_from=webapi&authKey=GTp/2jT2HKwTOFvEVxFcd4aAr3TaFDDAcoBdUCR8NZaOAIUZmZrkuWf9GXFxQjp3",
      desktop: "https://qm.qq.com/cgi-bin/qm/qr?k=8sLZ-Hitjhcx9QJZXerKY0O5f0IMQ1v8&jump_from=webapi&authKey=GTp/2jT2HKwTOFvEVxFcd4aAr3TaFDDAcoBdUCR8NZaOAIUZmZrkuWf9GXFxQjp3"
    }
  }, 
  
  // Telegram配置
  telegram: {
    username: 'UNDSClub',
    channelName: 'UNDS Club',
    joinLink: {
      mobile: 'tg://resolve?domain=UNDSClub',
      desktop: 'https://t.me/UNDSClub'
    }
  },
}; 

// 全局默认设置
const defaults = {
  // 根据语言推荐不同平台
  recommendedPlatform: {
    zh: 'qq',      // 中文用户推荐QQ
    en: 'telegram' // 英文用户推荐Telegram
  },
  // 自动跳转超时时间(秒)
  redirectTimeout: 5
};

/**
 * 获取平台配置
 * @param {string} platform - 平台名称 (qq/telegram)
 * @returns {Object} 平台配置对象
 */
const getPlatformConfig = (platform) => {
  return socialPlatforms[platform] || null;
};

/**
 * 根据语言获取推荐平台
 * @param {string} language - 语言代码 (zh/en)
 * @returns {string} 推荐的平台名称
 */
const getRecommendedPlatform = (language) => {
  return defaults.recommendedPlatform[language] || defaults.recommendedPlatform.en;
};

/**
 * 获取平台加入链接
 * @param {string} platform - 平台名称
 * @param {boolean} isMobile - 是否为移动设备
 * @returns {string} 加入链接
 */
const getJoinLink = (platform, isMobile = false) => {
  const config = getPlatformConfig(platform);
  if (!config || !config.joinLink) return null;
  
  return isMobile ? config.joinLink.mobile : config.joinLink.desktop;
};

// 完整配置对象
const socialConfig = {
  platforms: socialPlatforms,
  defaults,
  getPlatformConfig,
  getRecommendedPlatform,
  getJoinLink,
};

// 导出所有配置和辅助函数
export {
  socialPlatforms,
  defaults,
  getPlatformConfig,
  getRecommendedPlatform,
  getJoinLink,
};

// 默认导出完整配置对象
export default socialConfig;