import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Space,
    Tag,
    Select,
    Dropdown
} from 'antd'

import {
    useContext,
    useState,
    useEffect,
    useRef
} from 'react'

import { motion } from 'framer-motion'
import { Context } from './store/Context'
import locales, { getBrowserLanguage } from './locales'
import './App.css'
// 导入社交平台配置
import socialConfig from 'config/socialConfig'
import { SendOutlined, QqOutlined, GlobalOutlined } from '@ant-design/icons'

// 主题色定义 - 明亮现代的游戏模拟器风格
const theme = {
    primary: '#4f6ef5', // 更深邃的蓝色，增强游戏模拟器质感
    secondary: '#ec8047', // 更温暖的橙色，增加层次感
    accent: '#e94db3', // 更饱和的粉色，增强对比度
    dark: '#0d1424', // 更深沉的蓝黑背景，提升沉浸感
    darkGradient: 'linear-gradient(145deg, #0d1424 0%, #1a2436 100%)', // 更深邃的蓝色渐变
    lightGradient: 'linear-gradient(145deg, #eef3fa 0%, #dae5f0 100%)', // 更柔和的浅蓝灰渐变
    gameGradient: 'linear-gradient(180deg, #4f6ef5 0%, #3354e8 100%)', // 更深邃的蓝色渐变
    textPrimary: '#f0f6ff', // 更柔和的主要文本颜色
    textSecondary: '#c0d0e5', // 更协调的次要文本颜色
    cardBg: 'rgba(26, 36, 54, 0.65)', // 更深邃半透明的卡片背景
    cardShadow: '0 8px 20px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.03) inset', // 更深邃的卡片阴影
    glassMorphism: 'rgba(13, 20, 36, 0.7)', // 灰蒙玻璃态背景色
    glassBorder: 'rgba(79, 110, 245, 0.15)', // 灰蒙边框色
    // RGB颜色值 - 用于渐变和光效
    primaryRGB: '79, 110, 245', // 更深邃的蓝色 RGB
    secondaryRGB: '236, 128, 71', // 更温暖的橙色 RGB
    accentRGB: '233, 77, 179', // 更饱和的粉色 RGB
    darkRGB: '13, 20, 36', // 深色背景RGB
    lightRGB: '240, 246, 255' // 亮色RGB
}

// 添加字体定义
const fonts = {
    pixel: "'Press Start 2P', 'Pixel Font', monospace", // 像素风格字体
    heading: "'Audiowide', 'Oxanium', monospace", // 更现代的游戏风格标题字体
    body: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif", // 正文字体
    // 语言特定字体
    pixelEn: "'Audiowide', 'Oxanium', monospace", // 英文像素风格字体
    pixelZh: "'Press Start 2P', 'Pixel Font', monospace", // 中文像素风格字体
    headingEn: "'Audiowide', 'Oxanium', monospace", // 英文标题字体
    headingZh: "'Oxanium', 'Press Start 2P', monospace" // 中文标题字体
}

function Desktop() {
    const { state } = useContext(Context)
    const [locale, setLocale] = useState('en');
    const t = locales[locale]; // 当前语言的文本
    
    // 为打字机效果准备的特色列表 - 增加更多特色
    const [typewriterIndex, setTypewriterIndex] = useState(0);
    const [displayText, setDisplayText] = useState('');
    // 重构：添加明确的打字机状态
    const [typingState, setTypingState] = useState('typing'); // 'typing', 'completed', 'fading', 'changing'
    // 增加样式变化效果
    const [effectStyle, setEffectStyle] = useState(0);
    // 添加文本透明度状态用于过渡效果
    const [textOpacity, setTextOpacity] = useState(1);
    const effectStyles = [
        { bg: 'rgba(13, 20, 36, 0.65)', borderColor: theme.primaryRGB, icon: '✓', iconBg: theme.primary },
        { bg: 'rgba(19, 28, 50, 0.7)', borderColor: theme.secondaryRGB, icon: '⚡', iconBg: theme.secondary },
        { bg: 'rgba(30, 18, 45, 0.65)', borderColor: theme.accentRGB, icon: '🎮', iconBg: theme.accent },
        { bg: 'rgba(22, 32, 56, 0.75)', borderColor: theme.lightRGB, icon: '💾', iconBg: '#3e59e5' }
    ];
    
    const specialFeatures = {
        zh: [
            '高速模拟 DS/GBA/SNES 等经典游戏',
            '支持存档管理和快速存取',
            '兼容触控和手柄控制',
            '自定义按键映射和屏幕布局',
            '云同步和多设备支持',
            '内置游戏增强功能和金手指',
            '纯净无广告的游戏体验',
            '支持多种游戏ROM格式',
            '精确还原原机游戏体验',
            '定期更新优化性能与兼容性'
        ],
        en: [
            'High-speed emulation for DS/GBA/SNES games',
            'Save state management and quick load',
            'Compatible with touch and controller inputs',
            'Customize button mapping and screen layout',
            'Cloud sync and multi-device support',
            'Built-in game enhancement and cheat codes',
            'Clean ad-free gaming experience',
            'Support for multiple ROM formats',
            'Accurate original console experience',
            'Regular updates for performance and compatibility'
        ]
    };

    // 完全重构打字机效果
    useEffect(() => {
        // 获取当前语言的特性列表
        const currentFeatures = specialFeatures[locale];
        // 获取当前要显示的文本
        const currentText = currentFeatures[typewriterIndex];
        // 用于跟踪字符索引
        let charIndex = 0;
        // 定时器引用，用于清理
        let timer = null;

        // 根据当前状态执行不同的操作
        if (typingState === 'typing') {
            // 打字阶段：逐个字符显示文本
            timer = setInterval(() => {
                if (charIndex <= currentText.length) {
                    setDisplayText(currentText.substring(0, charIndex));
                    charIndex++;
                } else {
                    // 打字完成，清除定时器并切换到完成状态
                    clearInterval(timer);
                    setTypingState('completed');
                }
            }, 60); // 打字速度
        } 
        else if (typingState === 'completed') {
            // 完成阶段：显示完整文本一段时间后切换到淡出状态
            timer = setTimeout(() => {
                setTypingState('fading');
                setTextOpacity(0); // 开始淡出动画
            }, 3000); // 文本显示持续时间：3秒
        } 
        else if (typingState === 'fading') {
            // 淡出阶段：淡出动画完成后切换到更换文本状态
            timer = setTimeout(() => {
                setTypingState('changing');
            }, 600); // 淡出动画持续时间
        } 
        else if (typingState === 'changing') {
            // 更换文本阶段：切换到下一个文本并重置状态
            // 计算下一个文本索引
            const nextIndex = (typewriterIndex + 1) % currentFeatures.length;
            
            // 更新效果样式（每两个文本更换一次）
            if (nextIndex % 2 === 0) {
                setEffectStyle(prevStyle => (prevStyle + 1) % effectStyles.length);
            }
            
            // 切换到下一个文本前短暂延迟
            timer = setTimeout(() => {
                setDisplayText(''); // 清空文本
                setTextOpacity(1); // 重置透明度准备下一次显示
                setTypewriterIndex(nextIndex); // 切换到下一个文本
                setTypingState('typing'); // 重新开始打字阶段
            }, 400); // 文本切换延迟时间
        }

        // 清理函数：在组件卸载或状态变化时清除定时器
        return () => {
            if (timer) {
                clearTimeout(timer);
                clearInterval(timer);
            }
        };
    }, [typingState, typewriterIndex, locale]); // 只依赖于这三个状态，减少不必要的重渲染
    
    // 选择使用哪个logo - 基于语言
    const getLogo = () => {
        // 如果有本地化logo就使用，否则使用默认logo
        return state[`appLogo_${locale}`] || state.appLogo;
    };
    
    // 尝试从localStorage获取语言设置
    useEffect(() => {
        const savedLocale = localStorage.getItem('locale');
        if (savedLocale) {
            setLocale(savedLocale);
        } else {
            // 检测浏览器语言
            setLocale(getBrowserLanguage());
        }
    }, []);
    
    // 切换语言
    const changeLocale = (value) => {
        setLocale(value);
        localStorage.setItem('locale', value);
    };
    
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 应用特点
    const features = [
        {
            title: t.desktop.highPerformance,
            description: t.desktop.highPerformanceDesc,
            icon: '⚡'
        },
        {
            title: t.desktop.multiPlatform,
            description: t.desktop.multiPlatformDesc,
            icon: '🎮'
        },
        {
            title: t.desktop.saveStates,
            description: t.desktop.saveStatesDesc,
            icon: '💾'
        }
    ]

    // 获取当前语言对应的字体
    const getFont = (type) => {
        if (type === 'pixel') {
            return locale === 'zh' ? fonts.pixelZh : fonts.pixelEn;
        } else if (type === 'heading') {
            return locale === 'zh' ? fonts.headingZh : fonts.headingEn;
        }
        return fonts.body;
    };
    
    // 优化特性文本间距和字体大小，适应不同语言
    const getTextSize = (baseSize) => {
        // 英文版本字体更适合阅读
        return locale === 'zh' ? baseSize : baseSize * 0.9;
    };
    
    // 添加文本行高计算，优化不同语言的垂直间距
    const getLineHeight = (base) => {
        return locale === 'zh' ? base : base * 1.2; // 英文需要更宽松的行高
    };

    return (
        <Layout style={{minWidth: '1000px'}}>
            {/* 导航栏 - 现代设计，更好地融合logo */}
            <Affix offsetTop={0}>
                <Layout.Header style={{
                    background: theme.dark, // 使用主题定义的深蓝色背景
                    height: '64px',
                    borderBottom: `1px solid rgba(${theme.primaryRGB}, 0.15)`, // 使用统一的边框色
                    padding: '0 24px',
                    position: 'relative',
                    zIndex: 100,
                }}>
                    <Row justify="space-between" align="middle" style={{height: '100%', maxWidth: '1200px', margin: '0 auto'}}>
                        {/* Logo区域 - 完全重新设计，更协调 */}
                        <Col>
                            <Row align="middle" style={{cursor: 'pointer'}} onClick={() => window.scrollTo(0, 0)}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                    background: `linear-gradient(135deg, rgba(${theme.primaryRGB}, 0.08) 0%, rgba(${theme.primaryRGB}, 0.01) 100%)`,
                                    padding: '5px 16px 5px 5px',
                                    borderRadius: '10px',
                                    border: `1px solid rgba(${theme.primaryRGB}, 0.15)`,
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)'
                                }}>
                                    <div style={{
                                        width: '42px',
                                        height: '42px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        position: 'relative',
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                                    }}>
                                        <Image 
                                            preview={false} 
                                            src={getLogo()} 
                                            style={{
                                                objectFit: 'contain'
                                            }}
                                        />
                                        {/* 微妙的光效 */}
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.15) 0%, transparent 100%)',
                                            pointerEvents: 'none'
                                        }}></div>
                                    </div>
                                    <Typography.Title level={4} style={{
                                        margin: '0',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        color: 'white',
                                        position: 'relative',
                                        zIndex: 2
                                    }}>
                                        {state.appName}
                                        {/* 微妙的下划线效果 */}
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '-2px',
                                            left: '0',
                                            width: '40%',
                                            height: '2px',
                                            background: `linear-gradient(90deg, rgba(${theme.primaryRGB}, 0.8), transparent)`,
                                            borderRadius: '1px'
                                        }}></div>
                                    </Typography.Title>
                                    {/* 装饰元素 */}
                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        width: '4px',
                                        height: '4px',
                                        borderRadius: '50%',
                                        background: theme.primary,
                                        opacity: 0.7,
                                        transform: 'translateY(-50%)'
                                    }}></div>
                                </div>
                            </Row>
                        </Col>
                        
                        {/* 导航按钮和语言切换 */}
                        <Col>
                            <Space size={16}>
                                <Dropdown
                                    menu={{
                                        items: [
                                            { key: 'en', label: 'English' },
                                            { key: 'zh', label: '中文' }
                                        ],
                                        onClick: ({ key }) => changeLocale(key)
                                    }}
                                    placement="bottomRight"
                                    overlayStyle={{ 
                                        background: theme.dark, 
                                        borderRadius: '8px' 
                                    }}
                                >
                                    <Button 
                                        type="text" 
                                        icon={<GlobalOutlined />} 
                                        style={{ 
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: `rgba(${theme.primaryRGB}, 0.12)`, // 微妙的背景
                                            borderRadius: '8px'
                                        }}
                                    />
                                </Dropdown>
                                
                                <motion.div whileHover={{scale: 1.02}} whileTap={{scale: 0.98}}>
                                    <Button 
                                        type="primary" 
                                        size="middle"
                                        style={{
                                            borderRadius: '8px',
                                            padding: '0 20px',
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            background: theme.primary,
                                            borderColor: theme.primary,
                                            boxShadow: `0 2px 8px rgba(${theme.primaryRGB}, 0.4)`
                                        }}
                                        onClick={() => window.open('/support', '_blank')}
                                    >
                                        {t.common.support}
                                    </Button>
                                </motion.div>
                            </Space>
                        </Col>
                    </Row>
                </Layout.Header>
            </Affix>
            
            <Layout.Content>
                {/* 主要内容区域 - 更明亮的背景 */}
                <div style={{
                    background: theme.darkGradient,
                    minHeight: 'calc(100vh - 64px)',
                    padding: '0',
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    {/* 游戏风格背景元素 - 更明显的网格 */}
                    <div className="game-grid-bg" style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `
                            linear-gradient(rgba(${theme.primaryRGB}, 0.04) 1px, transparent 1px), 
                            linear-gradient(90deg, rgba(${theme.primaryRGB}, 0.04) 1px, transparent 1px)
                        `,
                        backgroundSize: '30px 30px',
                        opacity: 0.8,
                        zIndex: 0
                    }}></div>
                    
                    {/* 主背景渐变光效 - 更明亮的效果 */}
                    <div className="hero-bg-pattern" style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `
                            radial-gradient(circle at 15% 25%, rgba(${theme.primaryRGB}, 0.15) 0%, transparent 45%), 
                            radial-gradient(circle at 85% 65%, rgba(${theme.accentRGB}, 0.12) 0%, transparent 55%),
                            radial-gradient(circle at 50% 85%, rgba(${theme.secondaryRGB}, 0.1) 0%, transparent 60%)
                        `,
                        pointerEvents: 'none',
                        zIndex: 0
                    }}></div>
                    
                    {/* 英雄区域 */}
                    <div style={{
                        padding: '80px 24px 96px',
                        maxWidth: '1200px',
                        margin: '0 auto',
                        textAlign: 'center',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        {/* 主标题 - 更游戏风格 */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{ 
                                display: 'flex', 
                                justifyContent: 'center' 
                            }}
                        >
                            <div className="title-glow-border game-console-border" style={{
                                padding: '12px 30px',
                                background: theme.glassMorphism, // 使用更深邃的灰蒙背景
                                backdropFilter: 'blur(10px)',
                                boxShadow: '0 10px 25px rgba(0,0,0,0.2), 0 0 0 1px rgba(79, 110, 245, 0.2) inset',
                                display: 'inline-block',
                                borderRadius: '10px',
                                border: '1px solid rgba(79, 110, 245, 0.2)'
                            }}>
                                <h1 className={`game-heading ${locale === 'en' ? 'en-heading' : ''}`} style={{
                                    fontSize: locale === 'en' ? '38px' : '42px',
                                    fontWeight: 'bold',
                                    marginBottom: '0',
                                    fontFamily: getFont('heading'),
                                    color: 'transparent',
                                    background: `linear-gradient(135deg, rgba(${theme.primaryRGB}, 1) 0%, rgba(${theme.secondaryRGB}, 1) 100%)`,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    textAlign: 'center',
                                    letterSpacing: locale === 'en' ? '1px' : '2px',
                                    textShadow: '0 2px 15px rgba(79, 110, 245, 0.4)',
                                    margin: '0',
                                    textTransform: 'uppercase'
                                }}>
                                    {locale === 'zh' ? 'UNDS 模拟器' : 'UNDS EMULATOR'}
                                </h1>
                            </div>
                        </motion.div>
                        
                        {/* 核心卖点标签 - 游戏风格 */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                        >
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                flexWrap: 'wrap',
                                gap: '10px',
                                marginTop: '32px'
                            }}>
                                <Tag className={`game-tag ${locale === 'en' ? 'en-tag' : ''}`} style={{
                                    background: `rgba(${theme.primaryRGB}, 0.15)`,
                                    border: `1px solid rgba(${theme.primaryRGB}, 0.3)`,
                                    color: '#fff',
                                    fontSize: getTextSize(14),
                                    padding: locale === 'en' ? '6px 12px' : '6px 14px',
                                    borderRadius: '6px',
                                    fontFamily: getFont('pixel'),
                                    letterSpacing: locale === 'en' ? '0.2px' : '0.5px',
                                    textShadow: '1px 1px 0 rgba(0,0,0,0.3)'
                                }}>
                                    ✓ {t.desktop.classicHandheldEmulation}
                                </Tag>
                                <Tag className={`game-tag ${locale === 'en' ? 'en-tag' : ''}`} style={{
                                    background: `rgba(${theme.secondaryRGB}, 0.15)`,
                                    border: `1px solid rgba(${theme.secondaryRGB}, 0.3)`,
                                    color: '#fff',
                                    fontSize: getTextSize(14),
                                    padding: locale === 'en' ? '6px 12px' : '6px 14px',
                                    borderRadius: '6px',
                                    fontFamily: getFont('pixel'),
                                    letterSpacing: locale === 'en' ? '0.2px' : '0.5px',
                                    textShadow: '1px 1px 0 rgba(0,0,0,0.3)'
                                }}>
                                    ✓ {t.desktop.dualScreenGames}
                                </Tag>
                                <Tag className={`game-tag ${locale === 'en' ? 'en-tag' : ''}`} style={{
                                    background: `rgba(${theme.accentRGB}, 0.15)`,
                                    border: `1px solid rgba(${theme.accentRGB}, 0.3)`,
                                    color: '#fff',
                                    fontSize: getTextSize(14),
                                    padding: locale === 'en' ? '6px 12px' : '6px 14px',
                                    borderRadius: '6px',
                                    fontFamily: getFont('pixel'),
                                    letterSpacing: locale === 'en' ? '0.2px' : '0.5px',
                                    textShadow: '1px 1px 0 rgba(0,0,0,0.3)'
                                }}>
                                    ✓ {t.desktop.highPerformanceEmulation}
                                </Tag>
                                <Tag className={`game-tag ${locale === 'en' ? 'en-tag' : ''}`} style={{
                                    background: 'rgba(255, 255, 255, 0.15)',
                                    border: '1px solid rgba(255, 255, 255, 0.3)',
                                    color: '#fff',
                                    fontSize: getTextSize(14),
                                    padding: locale === 'en' ? '6px 12px' : '6px 14px',
                                    borderRadius: '6px',
                                    fontFamily: getFont('pixel'),
                                    letterSpacing: locale === 'en' ? '0.2px' : '0.5px',
                                    textShadow: '1px 1px 0 rgba(0,0,0,0.3)'
                                }}>
                                    ✓ {t.desktop.saveStatesSupport}
                                </Tag>
                            </div>
                        </motion.div>
                        
                        {/* 添加AppStore下载按钮 */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            style={{ marginTop: '28px', marginBottom: '10px' }}
                        >
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.98 }}
                                style={{ display: 'inline-block' }}
                            >
                                <Button 
                                    type="primary" 
                                    size="large"
                                    style={{
                                        borderRadius: '10px',
                                        padding: '8px 28px',
                                        height: 'auto',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        background: 'linear-gradient(135deg, #007bff, #002c64)',
                                        borderColor: 'rgba(0, 123, 255, 0.5)',
                                        boxShadow: '0 6px 18px rgba(0, 123, 255, 0.3), 0 0 0 1px rgba(255, 255, 255, 0.1) inset',
                                        backdropFilter: 'blur(8px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                    onClick={() => window.location.href = '/download'}
                                >
                                    <svg viewBox="0 0 24 24" width="22" height="22" fill="white">
                                        <path d="M17.05 20.28c-.98.95-2.05.88-3.08.45-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.45C2.32 15.63 3.06 7.08 8.53 6.5c1.53-.15 2.57.4 3.47.8.64.29 1.24.74 2.54.74 1.24 0 1.8-.41 2.54-.75.98-.46 1.99-.92 3.25-.67 1.33.27 2.41.96 3.09 2.05-2.31 1.35-1.92 4.67.5 5.76-.57 1.38-1.27 2.77-2.47 4.06-.77.8-1.5 1.33-2.4 1.79z"></path>
                                        <path d="M15.47 3.26c.75-.95.7-2.47.57-3.26-.63.04-1.37.38-1.9.85-.49.44-1.01 1.17-1.01 2.26 0 .09.02.19.03.28.06.44.33.83.71 1.04.05-.09.09-.19.14-.28.3-.59.82-1.38 1.46-.89z"></path>
                                    </svg>
                                    {locale === 'zh' ? 'App Store 下载' : 'Download on App Store'}
                                </Button>
                            </motion.div>
                        </motion.div>
                        
                        {/* 打字机效果展示特色功能 - 动态样式，针对英文优化 */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            style={{ marginTop: '24px', marginBottom: '20px' }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '60px'
                            }}>
                                <div 
                                    className="typewriter-container" 
                                    style={{
                                        background: effectStyles[effectStyle].bg,
                                        backdropFilter: 'blur(12px)',
                                        padding: '16px 28px',
                                        borderRadius: '10px',
                                        border: `1px solid rgba(${effectStyles[effectStyle].borderColor}, 0.2)`,
                                        boxShadow: `0 10px 30px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(${effectStyles[effectStyle].borderColor}, 0.1)`,
                                        maxWidth: locale === 'en' ? '680px' : '650px', // 英文容器宽度调整
                                        width: '100%',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        transition: 'all 0.8s ease-out'
                                    }}
                                >
                                    {/* 背景闪光效果 - 动态变化 */}
                                    <div className="typewriter-glow" style={{
                                        position: 'absolute',
                                        top: '-40%',
                                        left: '0',
                                        width: '100%',
                                        height: '200%',
                                        background: `radial-gradient(ellipse at center, rgba(${effectStyles[effectStyle].borderColor}, 0.15) 0%, rgba(${theme.secondaryRGB}, 0.05) 40%, transparent 70%)`,
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                        zIndex: 0,
                                        transition: 'background 0.8s ease-out'
                                    }}></div>
                                    
                                    {/* 打字机图标 - 动态变化 */}
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            width: '24px',
                                            height: '24px',
                                            background: effectStyles[effectStyle].iconBg,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: `0 0 12px rgba(${effectStyles[effectStyle].borderColor}, 0.5)`,
                                            marginRight: '14px',
                                            flexShrink: 0,
                                            position: 'relative',
                                            zIndex: 1,
                                            transition: 'all 0.8s ease-out'
                                        }}>
                                            <span style={{ color: 'white', fontSize: '12px' }}>{effectStyles[effectStyle].icon}</span>
                                        </div>
                                        <Typography.Text 
                                            className={`typewriter-text ${locale === 'en' ? 'en-typewriter' : 'pixel-text'}`}
                                            style={{
                                                color: 'white',
                                                fontSize: locale === 'en' ? '14px' : '16px', // 增大英文字体大小提高可读性
                                                fontWeight: '500',
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'relative',
                                                zIndex: 1,
                                                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                                                opacity: textOpacity,
                                                transition: 'opacity 0.6s ease',
                                                fontFamily: getFont('pixel'),
                                                letterSpacing: locale === 'en' ? '0.2px' : '0.5px', // 优化英文字母间距
                                                lineHeight: getLineHeight(1.5)
                                            }}
                                        >
                                            {displayText}
                                            <span 
                                                className="typewriter-cursor" 
                                                style={{
                                                    display: typingState === 'typing' ? 'inline-block' : 'none',
                                                    width: '3px',
                                                    height: '20px',
                                                    background: theme.accent,
                                                    marginLeft: '2px',
                                                    animation: 'blink 1s infinite',
                                                    position: 'relative',
                                                    top: '1px'
                                                }}
                                            ></span>
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    
                    {/* 特点部分 - 明亮的设计 */}
                    <div style={{ 
                        background: '#1a2436', // 更深邃的蓝色背景
                        padding: '80px 24px', 
                        borderTop: `1px solid rgba(${theme.primaryRGB}, 0.15)`,
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        {/* 添加灰蒙背景效果 */}
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: `linear-gradient(145deg, rgba(${theme.darkRGB}, 0.4) 0%, rgba(${theme.darkRGB}, 0.5) 100%)`,
                            backdropFilter: 'blur(4px)',
                            zIndex: 0
                        }}></div>
                        
                        <div style={{ maxWidth: '1200px', margin: '0 auto', position: 'relative', zIndex: 1 }}>
                            <Row justify="center" style={{ marginBottom: '60px' }}>
                                <Col xs={24} md={18} lg={16} xl={14}>
                                    <Typography.Title level={2} style={{
                                        textAlign: 'center',
                                        fontSize: locale === 'en' ? '30px' : '32px', // 英文标题字体大小调整
                                        fontWeight: '600',
                                        marginBottom: '16px',
                                        color: 'white'
                                    }}>
                                        {t.desktop.endorsementTitle}
                                    </Typography.Title>
                                    <Typography.Paragraph style={{
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        color: theme.textPrimary,
                                        marginBottom: '8px'
                                    }}>
                                        {t.desktop.endorsementText}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph style={{
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        color: theme.textSecondary,
                                    }}>
                                        {t.desktop.endorsementDesc}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            
                            <Row gutter={[40, 40]} justify="center">
                                {features.map((feature, index) => (
                                    <Col xs={24} sm={8} key={index}>
                                        <motion.div
                                            initial={{ opacity: 0, y: 30 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: index * 0.1 }}
                                            viewport={{ once: true }}
                                        >
                                            <div style={{
                                                background: theme.cardBg,
                                                borderRadius: '16px',
                                                padding: '32px 24px',
                                                height: '100%',
                                                boxShadow: theme.cardShadow,
                                                border: `1px solid ${theme.glassBorder}`,
                                                transition: 'transform 0.2s, box-shadow 0.2s',
                                                backdropFilter: 'blur(8px)',
                                                cursor: 'default'
                                            }} className="feature-card glass-card">
                                                <div style={{
                                                    fontSize: '32px',
                                                    marginBottom: '16px',
                                                    background: theme.gameGradient,
                                                    width: '60px',
                                                    height: '60px',
                                                    borderRadius: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    boxShadow: `0 8px 16px rgba(${theme.primaryRGB}, 0.25)`,
                                                    border: '2px solid rgba(255, 255, 255, 0.1)'
                                                }}>
                                                    {feature.icon}
                                                </div>
                                                <Typography.Title level={3} className={`feature-title ${locale === 'en' ? 'en-feature-title' : ''}`} style={{
                                                    fontSize: locale === 'en' ? '16px' : '20px', // 减小英文标题字体大小
                                                    marginBottom: '12px',
                                                    fontWeight: '600',
                                                    color: 'white',
                                                    fontFamily: getFont('heading'),
                                                    letterSpacing: locale === 'en' ? '0.2px' : '0.5px' // 减小英文字母间距
                                                }}>
                                                    {feature.title}
                                                </Typography.Title>
                                                <Typography.Paragraph style={{
                                                    fontSize: '15px',
                                                    color: theme.textSecondary,
                                                    margin: 0,
                                                    lineHeight: locale === 'en' ? '1.7' : '1.6' // 英文行高调整
                                                }}>
                                                    {feature.description}
                                                </Typography.Paragraph>
                                            </div>
                                        </motion.div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                    
                    {/* 社区与平台部分 - 更鲜明的设计 */}
                    <div style={{ 
                        padding: '80px 24px', 
                        background: theme.dark, 
                        position: 'relative',
                        overflow: 'hidden',
                        borderTop: `1px solid rgba(${theme.primaryRGB}, 0.15)`
                    }}>
                        {/* 背景装饰元素 */}
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `radial-gradient(circle at 85% 15%, rgba(${theme.primaryRGB}, 0.15) 0%, transparent 50%)`,
                            pointerEvents: 'none'
                        }}></div>
                        
                        <div style={{ maxWidth: '1200px', margin: '0 auto', position: 'relative', zIndex: 2 }}>
                            <Row gutter={[60, 60]} align="middle">
                                <Col xs={24} md={12}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 40 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6 }}
                                        viewport={{ once: true }}
                                    >
                                        <Typography.Title level={2} style={{
                                            fontSize: '32px',
                                            fontWeight: '600',
                                            marginBottom: '20px',
                                            color: 'white',
                                            position: 'relative',
                                            display: 'inline-block'
                                        }}>
                                            {t.desktop.joinOurCommunity}
                                            <div style={{
                                                position: 'absolute',
                                                bottom: '-5px',
                                                left: '0',
                                                width: '40%',
                                                height: '3px',
                                                background: theme.accent,
                                                borderRadius: '4px'
                                            }}></div>
                                        </Typography.Title>
                                        <Typography.Paragraph style={{
                                            fontSize: '16px',
                                            color: theme.textPrimary,
                                            marginBottom: '30px',
                                            lineHeight: '1.6'
                                        }}>
                                            {t.desktop.communityDescription}
                                        </Typography.Paragraph>
                                        
                                        <Row gutter={[16, 16]}>
                                            <Col>
                                                <motion.div
                                                    whileHover={{ scale: 1.02 }}
                                                    whileTap={{ scale: 0.98 }}
                                                >
                                                    <Button 
                                                        size="large"
                                                        icon={<SendOutlined style={{fontSize: '18px'}} />}
                                                        style={{
                                                            borderRadius: '8px',
                                                            height: 'auto',
                                                            padding: '10px 20px',
                                                            fontSize: '15px',
                                                            background: '#0088cc',
                                                            color: 'white',
                                                            border: 'none',
                                                            boxShadow: '0 4px 12px rgba(0, 136, 204, 0.3)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '8px'
                                                        }}
                                                        onClick={() => window.open(socialConfig.getJoinLink('telegram', false), '_blank')}
                                                    >
                                                        {t.desktop.telegramCommunity}
                                                    </Button>
                                                </motion.div>
                                            </Col>
                                            <Col>
                                                <motion.div
                                                    whileHover={{ scale: 1.02 }}
                                                    whileTap={{ scale: 0.98 }}
                                                >
                                                    <Button 
                                                        size="large"
                                                        icon={<QqOutlined style={{fontSize: '18px'}} />}
                                                        style={{
                                                            borderRadius: '8px',
                                                            height: 'auto',
                                                            padding: '10px 20px',
                                                            fontSize: '15px',
                                                            background: '#12B7F5',
                                                            color: 'white',
                                                            border: 'none',
                                                            boxShadow: '0 4px 12px rgba(18, 183, 245, 0.3)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '8px'
                                                        }}
                                                        onClick={() => window.open(socialConfig.getJoinLink('qq', false), '_blank')}
                                                    >
                                                        {t.desktop.qqCommunity}
                                                    </Button>
                                                </motion.div>
                                            </Col>
                                        </Row>
                                    </motion.div>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 40 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, delay: 0.2 }}
                                        viewport={{ once: true }}
                                    >
                                        <Typography.Title level={2} style={{
                                            fontSize: '32px',
                                            fontWeight: '600',
                                            marginBottom: '30px',
                                            color: 'white',
                                            position: 'relative',
                                            display: 'inline-block'
                                        }}>
                                            {t.desktop.supportedPlatforms}
                                            <div style={{
                                                position: 'absolute',
                                                bottom: '-5px',
                                                left: '0',
                                                width: '40%',
                                                height: '3px',
                                                background: theme.secondary,
                                                borderRadius: '4px'
                                            }}></div>
                                        </Typography.Title>
                                        <Row gutter={[24, 24]}>
                                            {/* 使用UNDS Logo替代平台图标 */}
                                            {Array(5).fill().map((_, index) => (
                                                <Col span={8} key={index} style={{ textAlign: 'center' }}>
                                                    <div style={{ 
                                                        padding: '16px', 
                                                        background: theme.glassMorphism, 
                                                        borderRadius: '12px', 
                                                        boxShadow: theme.cardShadow,
                                                        backdropFilter: 'blur(5px)',
                                                        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                                        cursor: 'pointer',
                                                        height: '72px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: `1px solid ${theme.glassBorder}`
                                                    }} className="platform-card glass-card">
                                                        {/* <Image 
                                                            height={40} 
                                                            preview={false} 
                                                            src={getLogo()}
                                                            style={{ filter: 'brightness(0) invert(1)', opacity: 0.9 }}
                                                        /> */}
                                                    </div>
                                                </Col>
                                            ))}
                                            
                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                <div style={{ 
                                                    padding: '16px', 
                                                    background: theme.glassMorphism, 
                                                    borderRadius: '12px', 
                                                    boxShadow: theme.cardShadow,
                                                    backdropFilter: 'blur(5px)',
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    justifyContent: 'center', 
                                                    height: '72px',
                                                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                    border: `1px solid ${theme.glassBorder}`
                                                }} className="platform-card glass-card">
                                                    {/* 闪光效果 */}
                                                    <div className="card-shine"></div>
                                                    <Typography.Text style={{ fontSize: '16px', fontWeight: '500', color: theme.textPrimary }}>
                                                        {t.desktop.andMore}
                                                    </Typography.Text>
                                                </div>
                                            </Col>
                                        </Row>
                                    </motion.div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Layout.Content>
            
            {/* 页脚 */}
            <Layout.Footer style={{
                background: theme.dark,
                padding: '24px',
                textAlign: 'center',
                borderTop: `1px solid rgba(${theme.primaryRGB}, 0.1)`
            }}>
                <div style={{ marginBottom: '12px' }}>
                    <Space split={<span style={{ color: `rgba(${theme.lightRGB}, 0.25)`, margin: '0 8px' }}>·</span>}>
                        <a href="/policy/privacy" style={{ color: theme.textSecondary, textDecoration: 'none', fontSize: '14px', transition: 'color 0.3s' }} className="footer-link">
                            {locale === 'zh' ? '隐私协议' : 'Privacy Policy'}
                        </a>
                        <a href="/policy/payment" style={{ color: theme.textSecondary, textDecoration: 'none', fontSize: '14px', transition: 'color 0.3s' }} className="footer-link">
                            {locale === 'zh' ? '付费协议' : 'Payment Terms'}
                        </a>
                        <a href="/policy/terms" style={{ color: theme.textSecondary, textDecoration: 'none', fontSize: '14px', transition: 'color 0.3s' }} className="footer-link">
                            {locale === 'zh' ? '用户协议' : 'User Agreement'}
                        </a>
                    </Space>
                </div>
                <Typography.Text style={{ color: theme.textSecondary }}>
                    © {new Date().getFullYear()} {state.appName}. {t.common.allRightsReserved}
                </Typography.Text>
            </Layout.Footer>
            
            {/* 添加全局样式，游戏模拟器风格 */}
            <style>{`
                /* 导入游戏风格字体 */
                @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@400;500;600;700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
                
                /* 游戏控制台边框效果 */
                .game-console-border {
                    position: relative;
                    overflow: hidden;
                }
                
                .game-console-border:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -50%;
                    width: 200%;
                    height: 1px;
                    background: linear-gradient(90deg, 
                        transparent 0%, 
                        rgba(${theme.primaryRGB}, 0.3) 25%, 
                        rgba(${theme.primaryRGB}, 0.6) 50%,
                        rgba(${theme.primaryRGB}, 0.3) 75%,
                        transparent 100%
                    );
                    animation: lightBar 4s linear infinite;
                }
                
                .game-console-border:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -50%;
                    width: 200%;
                    height: 1px;
                    background: linear-gradient(90deg, 
                        transparent 0%, 
                        rgba(${theme.secondaryRGB}, 0.3) 25%, 
                        rgba(${theme.secondaryRGB}, 0.6) 50%,
                        rgba(${theme.secondaryRGB}, 0.3) 75%,
                        transparent 100%
                    );
                    animation: lightBar 4s linear infinite reverse;
                }
                
                @keyframes lightBar {
                    from { transform: translateX(0); }
                    to { transform: translateX(50%); }
                }
                
                /* 游戏标题风格 */
                .game-heading {
                    position: relative;
                    text-transform: uppercase;
                }
                
                .game-heading:after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 40%;
                    height: 2px;
                    background: linear-gradient(90deg, 
                        transparent, 
                        rgba(${theme.primaryRGB}, 0.8), 
                        rgba(${theme.secondaryRGB}, 0.8), 
                        transparent
                    );
                }
                
                /* 游戏标签风格 */
                .game-tag {
                    position: relative;
                    overflow: hidden;
                    transition: all 0.3s ease;
                    box-shadow: 0 3px 8px rgba(0,0,0,0.15);
                }
                
                .game-tag:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 5px 12px rgba(0,0,0,0.2);
                }
                
                .game-tag:after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 5px;
                    height: 5px;
                    background: rgba(255, 255, 255, 0.7);
                    border-radius: 50%;
                    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.5);
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
                
                .game-tag:hover:after {
                    opacity: 1;
                }
                
                /* 英文版优化 */
                .en-heading {
                    letter-spacing: 1px !important;
                    font-size: 38px !important;
                    font-family: 'Audiowide', 'Oxanium', monospace !important;
                    text-shadow: 0 2px 15px rgba(59, 130, 246, 0.4) !important;
                }
                
                .en-tag {
                    font-size: 12px !important;
                    letter-spacing: 0.3px !important;
                    line-height: 1.4 !important;
                    padding: 6px 12px !important;
                    font-weight: 500 !important;
                    font-family: 'Audiowide', 'Oxanium', monospace !important;
                }
                
                .en-typewriter {
                    font-family: 'Audiowide', 'Oxanium', monospace !important;
                    font-size: 14px !important;
                    letter-spacing: 0.3px !important;
                    line-height: 1.6 !important;
                }
                
                .en-feature-title {
                    font-size: 18px !important;
                    letter-spacing: 0.5px !important;
                    font-family: 'Audiowide', 'Oxanium', monospace !important;
                }
                
                /* 游戏动画效果 */
                @keyframes floatingGame {
                    0% { transform: translateY(0); }
                    50% { transform: translateY(-8px); }
                    100% { transform: translateY(0); }
                }
                
                @keyframes fadeFlicker {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0.8; }
                }
                
                @keyframes borderGlow {
                    0%, 100% { box-shadow: 0 0 5px rgba(59, 130, 246, 0.5), 0 10px 25px rgba(0,0,0,0.2); }
                    50% { box-shadow: 0 0 15px rgba(59, 130, 246, 0.7), 0 10px 25px rgba(0,0,0,0.2); }
                }
                
                @keyframes scanline {
                    0% { transform: translateY(-100%); }
                    100% { transform: translateY(100%); }
                }
                
                /* 游戏设备展示样式 */
                .main-device-animation {
                    animation: floatingGame 5s ease-in-out infinite;
                }
                
                /* 游戏光效 */
                .glow-effect.primary {
                    animation: pulseGlow 8s ease-in-out infinite;
                }
                
                .glow-effect.secondary {
                    animation: pulseGlow2 9s ease-in-out infinite;
                }
                
                .glow-effect.accent {
                    animation: pulseGlow3 7s ease-in-out infinite;
                }
                
                @keyframes pulseGlow {
                    0% { opacity: 0.5; filter: blur(50px); }
                    50% { opacity: 0.7; filter: blur(60px); }
                    100% { opacity: 0.5; filter: blur(50px); }
                }
                
                @keyframes pulseGlow2 {
                    0% { opacity: 0.6; filter: blur(45px); transform: scale(1); }
                    50% { opacity: 0.75; filter: blur(50px); transform: scale(1.03); }
                    100% { opacity: 0.6; filter: blur(45px); transform: scale(1); }
                }
                
                @keyframes pulseGlow3 {
                    0% { opacity: 0.55; filter: blur(40px); }
                    50% { opacity: 0.7; filter: blur(45px); }
                    100% { opacity: 0.55; filter: blur(40px); }
                }
                
                /* 其他改进和优化样式 */
                .feature-card {
                    backdrop-filter: none;
                    transition: all 0.3s ease;
                    border: 1px solid rgba(59, 130, 246, 0.15);
                    position: relative;
                    overflow: hidden;
                    background: rgba(30, 41, 59, 0.7); /* 更明亮的蓝色背景 */
                }
                
                .feature-card:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.5), transparent);
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
                
                .feature-card:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(90deg, transparent, rgba(251, 146, 60, 0.5), transparent);
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
                
                .feature-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(59, 130, 246, 0.2) inset !important;
                    border: 1px solid rgba(59, 130, 246, 0.25);
                    background: rgba(30, 41, 59, 0.8) !important;
                }
                
                /* 响应式调整 */
                @media (max-width: 768px) {
                    .en-typewriter {
                        font-size: 12px !important;
                    }
                    
                    .en-heading {
                        font-size: 32px !important;
                    }
                    
                    .en-tag {
                        font-size: 11px !important;
                        padding: 5px 8px !important;
                    }
                }
            `}</style>
        </Layout>
    )
}

export default Desktop
