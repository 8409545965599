import React, { useState, useEffect } from 'react';
import { Layout, Typography, Row, Col, Card, Space, Button, Divider } from 'antd';
import { motion } from 'framer-motion';
import { Context } from '../store/Context';
import { useContext } from 'react';
import { HeartOutlined, GlobalOutlined, SendOutlined } from '@ant-design/icons';
import { getLocale, getBrowserLanguage } from '../locales';

// 游戏模拟器主题
const theme = {
  // 主色调 - 紫色系
  primary: '#6c5ce7',
  primaryLight: '#a29bfe',
  primaryDark: '#5341d8',
  
  // 次要色 - 青色系
  secondary: '#00cec9',
  secondaryLight: '#81ecec',
  secondaryDark: '#00b5b0',
  
  // 强调色 - 加入一点橙色元素
  accent: '#fd79a8',
  accentLight: '#fab1a0',
  
  // 背景色和文本色
  bgDark: '#1e1e2e',
  bgMedium: '#2d2d3f',
  bgLight: '#393a58',
  textPrimary: '#ffffff',
  textSecondary: '#d0d0d7',
  textMuted: '#a0a0b0',
  
  // 渐变色
  gradientPrimary: 'linear-gradient(120deg, #6c5ce7 0%, #5341d8 100%)',
  gradientSecondary: 'linear-gradient(120deg, #00cec9 0%, #00b5b0 100%)',
  gradientAccent: 'linear-gradient(120deg, #fd79a8 0%, #fab1a0 100%)',
  gradientBackground: 'linear-gradient(150deg, #1e1e2e 0%, #2d2d3f 100%)'
};

function Thanks() {
    const { state } = useContext(Context);
    const [locale, setLocale] = useState(getBrowserLanguage());
    const localeText = getLocale(locale);

    useEffect(() => {
        // Add some smooth page entrance animation
        document.body.style.overflowX = 'hidden';
        document.body.style.background = theme.bgDark;
        
        return () => {
            // Cleanup
            document.body.style.background = '';
        };
    }, []);

    const toggleLanguage = () => {
        const newLocale = locale === 'zh' ? 'en' : 'zh';
        setLocale(newLocale);
    };

    const { Title, Paragraph, Text } = Typography;
    const { Header, Content, Footer } = Layout;

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                duration: 0.8,
                when: "beforeChildren",
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { 
            y: 0, 
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    return (
        <Layout style={{ minHeight: '100vh', background: theme.bgDark, color: theme.textPrimary }}>
            <Header style={{ 
                position: 'sticky', 
                top: 0, 
                zIndex: 1, 
                width: '100%', 
                background: theme.bgMedium,
                padding: '0 24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Title level={3} style={{ margin: 0, color: theme.textPrimary }}>
                        {localeText.thanksPage.title}
                    </Title>
                </div>
                <Button 
                    type="text" 
                    onClick={toggleLanguage} 
                    icon={<GlobalOutlined />} 
                    style={{ color: theme.textSecondary }}
                >
                    {locale === 'zh' ? 'English' : '中文'}
                </Button>
            </Header>

            <Content style={{ padding: '24px 50px' }}>
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.div variants={itemVariants}>
                        <Card 
                            style={{ 
                                marginBottom: 24, 
                                background: theme.bgMedium, 
                                borderRadius: 16,
                                border: 'none',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                            }}
                        >
                            <div style={{ textAlign: 'center', padding: '20px 0' }}>
                                <HeartOutlined style={{ fontSize: 64, color: theme.accent, marginBottom: 24 }} />
                                <Title level={2} style={{ color: theme.textPrimary, marginBottom: 16 }}>
                                    {localeText.thanksPage.mainTitle}
                                </Title>
                                <Paragraph style={{ color: theme.textSecondary, fontSize: 18, maxWidth: '800px', margin: '0 auto 32px' }}>
                                    {localeText.thanksPage.subtitle}
                                </Paragraph>
                                <Paragraph style={{ color: theme.textSecondary, fontSize: 16, maxWidth: '700px', margin: '0 auto 32px' }}>
                                    {localeText.thanksPage.contactUs}
                                </Paragraph>
                                <Button 
                                    type="primary"
                                    size="large" 
                                    icon={<SendOutlined />}
                                    style={{ 
                                        background: theme.gradientPrimary,
                                        border: 'none',
                                        borderRadius: 8,
                                        height: 48,
                                        padding: '0 32px',
                                        fontSize: 16
                                    }}
                                    onClick={() => window.location.href = '/contact'}
                                >
                                    {localeText.thanksPage.contactButton}
                                </Button>
                            </div>
                        </Card>
                    </motion.div>
                </motion.div>
            </Content>

            <Footer style={{ 
                textAlign: 'center', 
                background: theme.bgMedium,
                color: theme.textMuted,
                borderTop: `1px solid ${theme.bgLight}`
            }}>
                ©{new Date().getFullYear()} {localeText.footer.rights}
            </Footer>
        </Layout>
    );
}

export default Thanks; 